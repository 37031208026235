.home {
  display: flex;
  flex-direction: column;
  row-gap: var(--spacing-200);
  justify-content: center;
  align-items: center;

  width: 100%;
  margin: 0 auto;

  text-align: center;
  text-wrap: pretty;
}
