.sections {
  display: grid;
  grid-template-columns: repeat(auto-fit, 320px);
  grid-gap: var(--spacing-300);
  justify-content: center;

  width: 100%;

  &__item {
    padding: var(--spacing-300);

    color: var(--surface-inverted);
    font-size: var(--font-size-large);
    text-align: left;

    border: 1px solid var(--border-interactive-subtle);
    border-radius: var(--radius-lg);

    background-color: var(--surface-interactive-container);

    overflow: hidden;

    transition:
      border-color 0.135s,
      background-color 0.135s;

    &:hover {
      border-color: var(--border-interactive-subtle-hover);

      background-color: var(--surface-interactive-container-hover);
    }

    &:active {
      border-color: var(--border-interactive-subtle-pressed);

      background-color: var(--surface-interactive-container-pressed);
    }

    img {
      height: auto;
      max-width: 100%;
    }
  }

  a {
    text-decoration: none;
  }

  &__title {
    margin: var(--spacing-300) 0 var(--spacing-50);

    color: var(--surface-inverted);
    font-weight: 600;
  }
}

@media (width >= 960px) and (width <= 1279px) {
  .sections {
    grid-template-columns: repeat(2, 320px);
  }
}

@media (width >= 1280px) {
  .sections {
    grid-template-columns: repeat(4, 320px);
  }
}
